const allgames = [
  {
    id: 1,
    title: "ViewFinder",
    imageUrl: "images/viewfinder.jpg",
    ratings: {
      verygood: 0,
      good: 0,
      decent: 0,
      bad: 0,
    },
    description:
      "Viewfinder is a mind-bending first person adventure game in which you can bring pictures to life by placing them into the world. It tells the story of the ever changing world, human experience and relationships, meaningful and misguided passion for change, and overcoming loss.",
    developer: "By Sad Owl Studios",
    genre: "Genre: Puzzle",
    release: "Release Date: July 18 2023",
    platform: "Platforms: PC, PS5, PS4",
  },
  {
    id: 2,
    title: "Chants of Sennaar",
    imageUrl: "images/chants.jfif",
    ratings: {
      verygood: 0,
      good: 0,
      decent: 0,
      bad: 0,
    },
    description:
      "Unveil mysteries behind the fragments of the past Divided since the dawn of time.",
    developer: "By Rundisc",
    genre: "Genre: Adventure, Puzzle",
    release: "Release Date: September 5 2023",
    platform: "Platforms: PC, Switch, PS4, XBOX ONE",
  },
  {
    id: 3,
    title: "Wall World",
    imageUrl: "images/wallworld2.jpg",
    ratings: {
      verygood: 0,
      good: 0,
      decent: 0,
      bad: 0,
    },
    description:
      "A mining rogue-lite with tower defense elements. Explore procedurally generated mines and discover fantastical biomes. Find resources and technologies for purchasing valuable upgrades. Fight off hordes of aggressive monsters using your mobile base.",
    developer: "By Alawar",
    genre: "Genre: Adventure",
    release: "Release Date: April 5 2023",
    platform: "Platforms: PC, Switch, PS5, XBOX SERIES X, SERIES S",
  },
  // {
  //   id: 4,
  //   title: "Edenfall: Legacy of the First Wardens",
  //   imageUrl: "images/edenfall.jfif",
  //   description:
  //     "Untangle the mysteries of Eden - a forgotten realm as Venya, a young girl.",
  //   developer: "By Everflux Games",
  //   genre: "Genre: Adventure",
  //   release: "Release Date: TBA",
  //   platform: "Platforms: PC",
  // },
  {
    id: 5,
    title: "Isles of Sea and Sky",
    imageUrl: "images/seasky.jpg",
    ratings: {
      verygood: 0,
      good: 0,
      decent: 0,
      bad: 0,
    },
    description:
      " Solve novel block-pushing puzzles while unearthing a mystifying narrative.",
    developer: "By Cicada Games",
    genre: "Genre: Adventure, Puzzle",
    release: "Release Date: March 31 2024",
    platform: "Platforms: PC, Switch",
  },
  {
    id: 6,
    title: "Against the Storm",
    imageUrl: "images/storm.jpg",
    ratings: {
      veryGood: 0,
      good: 0,
      decent: 0,
      bad: 0,
    },
    description:
      "A dark fantasy city builder where you must rebuild civilization in the face of apocalyptic rains. ",
    developer: "By Eremite Games",
    genre: "Genre: Simulation",
    release: "Release Date: December 8 2023",
    platform: "Platforms: PC",
  },
  {
    id: 7,
    title: "Final Profit: A Shop",
    imageUrl: "images/finalprofit.jpg",
    ratings: {
      verygood: 0,
      good: 0,
      decent: 0,
      bad: 0,
    },
    description:
      "Gather products and find customers. Spend your hard-earned profits on upgrades and investments. Make far-reaching choices as you expand your business. ",
    developer: "By Brent Arnold",
    genre: "Genre: Simulation",
    release: "Release Date: March 6 2023",
    platform: "Platforms: PC",
  },
  {
    id: 8,
    title: "Darkest Dungeon 2",
    imageUrl: "images/darkest.jpg",
    ratings: {
      verygood: 0,
      good: 0,
      decent: 0,
      bad: 0,
    },
    description:
      "Form a party, equip your stagecoach, and set off across the decaying landscape on a last gasp quest to avert the apocalypse.",
    developer: "By Red Hook Studios",
    genre: "Genre: Adventure",
    release: "Release Date: May 8 2023",
    platform: "Platforms: PC, PS4, PS5",
  },
  {
    id: 9,
    title: "Spaceflux",
    imageUrl: "images/spaceflux.jfif",
    ratings: {
      verygood: 0,
      good: 0,
      decent: 0,
      bad: 0,
    },
    description:
      "The psychedelic shooter where the map contains itself recursively, like a fractal. Experience arena deathmatches like never before in fractal worlds, infinite landscapes and fully destructible environments. ",
    developer: "By Calin Ardelean",
    genre: "Genre: Puzzle",
    release: "Release Date: Early Access, TBA",
    platform: "Platforms: PC",
  },
  // {
  //   id: 10,
  //   title: "Dethroned",
  //   imageUrl: "images/dethroned.jpg",
  //   description:
  //     "Lead an army of fantasy creatures through a dark procedural open-world in Dethroned - a roguelike tactics auto-battler.",
  //   developer: "By Irid Games",
  //   genre: "Genre: Adventure",
  //   release: "Release Date: Early Access, May 27 2024",
  //   platform: "Platforms: PC",
  // },
  // Add more games as needed
];

export default allgames;
